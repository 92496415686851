import React from 'react'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

import TitleHeader from '../components/titleheader'
import Card from '../components/card'

import corporateimage1 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Header.jpg'
import corpresponsibilityimage1 from '../assets/images/corporate-responsibility/Corporate_Responsibility_03.jpg'
import corpresponsibilityimage2 from '../assets/images/corporate-responsibility/Corporate_Responsibility_05.jpg'

import { trackPageData, getPageDetails } from '../analytics'

class CorporateResponsibility extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Corporate Responsibility | The Children's Place Corporate Website"
          description="The Children’s Place believes in doing good in the communities where we do business. Learn more on The Children's Place Corproate Website."
        />
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <TitleHeader
              image={corporateimage1}
              title="CORPORATE RESPONSIBILITY"
              addClass="corporateTitle"
            />
          </div>
        </div>

        <div class="row mb-4 mt-1">
          <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            <div>
              <p>
                The Children’s Place believes in doing good in the communities
                where we do business
                <br />
                both in the US and around the world. We are committed to:
              </p>
              <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <ul class="list-inline list-bullet text-left">
                    <li class="corp-inside">
                      Donating time, money and clothing to children and families
                      in need&nbsp;&nbsp;&nbsp;&nbsp;
                    </li>
                    <li class="corp-inside">
                      Investing in workplace programs that positively impact the
                      lives of those who help make our products
                    </li>
                    <li class="corp-inside">
                      Committing to environmental and responsible sourcing
                      practices in our supply chain
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-6 col-sm-12">
            <Link to="/responsible-sourcing">
              <Card
                image={corpresponsibilityimage1}
                title="RESPONSIBLE SOURCING"
                description=""
              ></Card>
            </Link>
          </div>
          <div class="row d-md-none mb-5" style={{ width: 100 }}></div>
          <div class="col-md-6 col-sm-12">
            <Link to="/philanthropic-programs">
              <Card
                image={corpresponsibilityimage2}
                title="PHILANTHROPIC PROGRAMS"
                description=""
              ></Card>
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CorporateResponsibility
